<template>
    <div class="auth-wrapper auth-v1">
        <div class="auth-inner">
            <v-card
                :disabled="loading"
                class="auth-card"
            >
                <v-card-title class="d-flex align-center justify-center py-7">
                    <router-link
                        to="/"
                        class="d-flex align-center"
                    >
                        <v-img
                            :src="appLogo"
                            max-height="30px"
                            max-width="30px"
                            alt="logo"
                            contain
                            class="me-3 "
                        ></v-img>

                        <h2 class="text-2xl font-weight-semibold">
                            {{ $trans('brand') }}
                        </h2>
                    </router-link>
                </v-card-title>

                <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                        {{ $trans('Forgot password?') }} 🔒
                    </p>
                    <p class="mb-2">
                        {{ $trans('hint.forgot_password.subtitle') }}
                    </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                    <v-form
                        v-model="isValid"
                        ref="forgotPasswordForm"
                        @submit.prevent
                    >
                        <v-text-field
                            v-model="email"
                            outlined
                            :rules="rules"
                            label="Email"
                            placeholder="john@example.com"
                            :error-messages="errors.email"
                        ></v-text-field>

                        <v-btn
                            :disabled="!isValid"
                            :loading="loading"
                            block
                            color="primary"
                            @click="onSubmit"
                        >
                            {{ $trans('hint.forgot_password.submit') }}
                        </v-btn>
                    </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                    <router-link
                        :to="{name:'auth.login'}"
                        class="d-flex align-center text-sm"
                    >
                        <v-icon
                            size="24"
                            color="primary"
                        >
                            mdi-chevron-left
                        </v-icon>
                        <span>{{ $trans('Back to sign in') }}</span>
                    </router-link>
                </v-card-actions>
            </v-card>
        </div>

        <!-- background triangle shape  -->
        <img
            class="auth-mask-bg"
            height="190"
            :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
        />

        <!-- tree -->
        <v-img
            class="auth-tree"
            width="247"
            height="185"
            src="@/assets/images/misc/tree.png"
        ></v-img>

        <!-- tree  -->
        <v-img
            class="auth-tree-3"
            width="377"
            height="289"
            src="@/assets/images/misc/tree-3.png"
        ></v-img>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            :timeout="2000"
        >
            {{ message }}
        </v-snackbar>
    </div>
</template>

<script>
import themeConfig from '@themeConfig'
import { forgotPassword } from '@apps/auth/api'
import Debugger from '@/utils/Debugger'
import EventEmitter from '@/plugins/EventEmitter'
import Notification from '@apps/notification/utils'
import { emailValidator, required } from '@/utils/validation'

export default {
    name: 'ForgotPassword',
    computed: {
        rules() {
            return [
                required,
                emailValidator
            ]
        }
    },
    data() {
        return {
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
            email: '',
            isPasswordVisible: false,
            loading: false,
            errors: {},
            snackbar: false,
            snackbarColor: 'success',
            message: '',
            isValid: true
        }
    },
    beforeCreate() {
        EventEmitter.emit('guest:bootstrap')
    },
    methods: {
        async onSubmit() {
            if(!this.$refs.forgotPasswordForm.validate()) {
                return
            }

            this.errors = {}
            this.loading = true

            try {
                this.$notification.disabled()
                const response = await forgotPassword({email: this.email})

                if(response.data.message) {
                    const notify = new Notification(response.data.message, {
                        type: Notification.TYPE.SUCCESS
                    })

                    notify.open()
                }
            } catch (error) {
                if(!error || !error.response) {
                    Debugger.error(error)
                }

                if(error.response.data.errors) {
                    this.errors = error.response.data.errors
                }

                if(error.response.data.message) {
                    this.errors = {email: [error.response.data.message]}
                }
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
